import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import { useSelector, useDispatch } from 'react-redux';

// const MenuItem = ({ title, imageUrl, imageAlt }) => (
//   <div className="menu-item">
//     <div className="menu-text">{title}</div>
//     <img loading="lazy" src={imageUrl} alt={imageAlt} className="menu-icon" />
//   </div>
// );

const NavMenu = () => {
 
  const [cartCount, setCartCount] = useState(0);
  const [isSticky, setIsSticky] = React.useState(false);
  

  const storeCartCount = useSelector(state => state?.commonData?.cartCount)
  const checkLocation = useSelector((state) => { return  state.commonData.location })
  useEffect(() => {
    setCartCount(storeCartCount)
  }, [storeCartCount])

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsSticky(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <nav className={`navigation-bar ${isSticky ? 'sticky' : ''}`} >
      {isSticky ?
        <Link to="/">
          <img src={logo} alt="logo" /> 
          <h1 className="stickyLogo"><span className="green">Seasonal</span> <span className="orange">Sale</span> <span className="red">Snapee</span></h1>
        </Link>
        : ''}
      <div className="menulistContainer">
        <Link to="/" className="menu-item">Home</Link>
        {/* <Link to="/about" className="menu-item">About Us</Link> */}
        {/* <Link to="/crackers" className="menu-item">Crackers</Link> */}
        {/* {checkLocation === "Bangalore" ?
        <Link to="/standard-fireworks" className="menu-item">Standard fireworks</Link>
        : " "} */}
        {/* <Link to="/contact" className="menu-item">Contact</Link> */}
      </div>
      <div className="headerCartCount">
        {cartCount > 0 && (<span>{cartCount}</span>)}
        <Link to="/cart"><i className="pi pi-shopping-cart" style={{ fontSize: '1.5rem' }}></i></Link>
      </div>
    </nav>
  );
};

export default NavMenu;