import * as React from "react";
import { NavLink  } from 'react-router-dom';

export default function AdminLeftMenu() {
    return (
        <div className="adminLeftMenu">
             <NavLink  to="/addminn">Search Order</NavLink >
             <NavLink  to="/addminn/orders">Orders</NavLink >
             <NavLink  to="/addminn/products">Products</NavLink >
             <NavLink  to="/addminn/location">Location</NavLink >
             <NavLink  to="/addminn/location-code">Location Code</NavLink >
             <NavLink  to="/addminn/general-data">General Data</NavLink >
             <NavLink  to="/addminn/home-banner">Home Banner</NavLink >
             <NavLink  to="/addminn/timer-banner">Timer Banner</NavLink >
             <NavLink  to="/addminn/category-banner">Cateory Banner</NavLink >
             <NavLink  to="/addminn/reports">Reports</NavLink >
             <NavLink  to="/addminn/message">Message Text</NavLink >


        </div>
    )
}