import {
    CREATE_LOCATION,
    RETRIEVE_LOCATIONS,
    UPDATE_LOCATION,
    DELETE_LOCATION,
    DELETE_ALL_LOCATIONS
  } from "./types";
  
  import LocationService from "../constants/location.services";
  
  export const createLocation = (banner) => async (dispatch) => {
    try {
      const res = await LocationService.create(banner);
      dispatch({
        type: CREATE_LOCATION,
        payload: res.data.data,
      });

      return Promise.resolve(res.data.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  export const retrieveLocations = () => async (dispatch) => {
    try {
      const res = await LocationService.getAll();
      dispatch({
        type: RETRIEVE_LOCATIONS,
        payload: res.data,
      });
    } catch (err) {
    }
  };
  
  export const updateLocation = (id, data) => async (dispatch) => {
    try {
      const res = await LocationService.update(id, data);
      dispatch({
        type: UPDATE_LOCATION,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  export const deleteLocation = (id) => async (dispatch) => {
    try {
      await LocationService.delete(id);
  
      dispatch({
        type: DELETE_LOCATION,
        payload: { id },
      });
    } catch (err) {
    }
  };
  
  export const deleteAllLocations = () => async (dispatch) => {
    try {
      const res = await LocationService.deleteAll();
  
      dispatch({
        type: DELETE_ALL_LOCATIONS,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  export const findLocationsByTitle = (title) => async (dispatch) => {
    try {
      const res = await LocationService.findByTitle(title);
  
      dispatch({
        type: RETRIEVE_LOCATIONS,
        payload: res.data,
      });
    } catch (err) {
    }
  };