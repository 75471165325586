import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import './banner.scss';
import { useSelector, useDispatch } from 'react-redux';
import { retrieveHomeBanners } from '../../services/actions/homeBanner';
import {connect} from 'react-redux';

function PromotionalBanner({ backgroundImage, headingText, subText, buttonText, buttonIcon }) {
  
  return (
    <section className="promotionalBanner">
      <img loading="lazy" src={backgroundImage} alt="" className="banner-image" />
      <div className="content">
        <h2 className="heading">{headingText}</h2>
        <p className="sub-text">{subText}</p>
        {buttonIcon.length > 0 ?
        <Link to={buttonIcon} className="shop-button">
          {buttonText}
        </Link>
        : ""}
      </div>
    </section>
  );
}

function PromotionalPage() {
  const [products, setProducts] = useState([]);

  const dispatch = useDispatch()
  const getBanner = useSelector((state) => { return  state.homeBanner.data })
   useEffect(() => {
    dispatch(retrieveHomeBanners())
   }, [dispatch])
   useEffect(() => {
    setProducts(getBanner)
   }, [getBanner])
  return (
    <div className="sideBanner">
      {products?.length > 0 ? products.slice(1)?.map((product)=> (
      <PromotionalBanner
        backgroundImage={product.image}
        headingText={product.title}
        subText={product.discount}
        buttonText="Shop Now"
        buttonIcon={product.link}
      />
      )): ''}
    </div>
  );
}

export default connect(null, {retrieveHomeBanners})(PromotionalPage);