import {
    LOCATION,
    CARTCOUNT,
    CARTITEMS,
    TOTALAMOUNT,
    ADDRESS
  } from "./types";


export const getLocation = (getLocation) => async (dispatch) => {
    try {
      dispatch({
        type: LOCATION,
        payload: getLocation,
      });
  
      return Promise.resolve(getLocation);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  
export const cartItems = (cart) => async (dispatch) => {
  try {
    dispatch({
      type: CARTITEMS,
      payload: cart,
    });
    return Promise.resolve(cart);
  } catch (err) {
    return Promise.reject(err);
  }
};

  
export const cartTotal = (total) => async (dispatch) => {
  try {
    dispatch({
      type: TOTALAMOUNT,
      payload: total,
    });
    return Promise.resolve(total);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const addressDetail = (address) => async (dispatch) => {
  try {
    dispatch({
      type: ADDRESS,
      payload: address,
    });
    return Promise.resolve(address);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const cartTotalCount = (count) => async (dispatch) => {
  try {
    dispatch({
      type: CARTCOUNT,
      payload: count,
    });
    return Promise.resolve(count);
  } catch (err) {
    return Promise.reject(err);
  }
};



