import { http } from '../../config/configApi';


class LocationService {
    getAll() {
        return http.get(`/location`);
    }

    get(id) {
        return http.get(`/location/${id}`);
    }

    create(data) {
        return http.post("/location", data);
    }

    update(id, data) {
        return http.put(`/location/${id}`, data);
    }

    delete(id) {
        return http.delete(`/location/${id}`);
    }

    deleteAll() {
        return http.delete(`/location`);
    }

    findByTitle(title) {
        return http.get(`/location?title=${title}`);
    }
}

export default new LocationService();

