import React from "react";
import { Link } from 'react-router-dom';
import logo from '../../assets/logo-white.png';
import CallIcon from '../../assets/header-contact-icon.svg';

function HomeHeader() {
    return (
        <header className="homeHeader">
            <nav className="navigation">
                <section className="logo-container">
                    <Link to="/">
                    <img src={logo} alt="SSS Logo" className="logo" />
                    <h1><span className="green">Seasonal</span> <span className="orange">Sale</span> <span className="red">Snapee</span></h1>
                    </Link>
                </section>
                <section className="contactInfo">
                    <img
                        loading="lazy"
                        src={CallIcon}
                        alt="Customer Service"
                        className="contactIcon"
                    />
                    <div className="contactDetails">
                        {/* <a href="/admin">Admin</a> */}
                        <h2 className="contactTitle">Customer Support</h2>
                        <p className="contactNumber">+91 9606775609</p>
                    </div>
                </section>
            </nav>
        </header>
    );
}



export default HomeHeader;