import { http } from '../../config/configApi';


class HomeBannerService {
    getAll() {
        return http.get(`/homeBanner`);
    }

    get(id) {
        return http.get(`/homeBanner/${id}`);
    }

    create(data) {
        return http.post("/homeBanner", data);
    }

    update(id, data) {
        return http.put(`/homeBanner/${id}`, data);
    }

    delete(id) {
        return http.delete(`/homeBanner/${id}`);
    }

    deleteAll() {
        return http.delete(`/homeBanner`);
    }

    findByTitle(title) {
        return http.get(`/homeBanner?title=${title}`);
    }
}

export default new HomeBannerService();

