import React from 'react';

const WhatsAppButton = () => {
  const phoneNumber = '9606775609'; // Replace with your phone number
  const message = ''; // Replace with your message
  const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  return (
    <div>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <button style={buttonStyle}><i class="pi pi-zoom pi-whatsapp"></i></button>
      </a>
    </div>
  );
};

// Optional: Define some basic styles for the button
const buttonStyle = {
  backgroundColor: '#25D366', // WhatsApp green color
  color: 'white',
  border: 'none',
  padding: '10px 20px',
  borderRadius: '5px',
  cursor: 'pointer',
  fontSize: '16px',
};

export default WhatsAppButton;
