import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useSelector, useDispatch } from 'react-redux';
import { getLocation, addressDetail, cartTotal, cartItems, cartTotalCount } from '../../services/actions/commonData';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { retrieveLocations } from '../../services/actions/location';
import LocationIcon from '../.././assets/location-icon.svg';

export default function Location(props) {
   const [selectedLocation, setSelectedLocation] = useState("");
  const [getDataLocation, setGetDataLocation] = useState("");
  const [choosedLocation, setChoosedLocation] = useState("");
  const [locationUrl, setLocationUrl] = useState("");
  const [locationDesc, setLocationDesc] = useState("");

  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const dispatch = useDispatch()

  // const locationOptions = [
  //   { id: 1, name: "Radiant lotus Appartment - Bangalore", url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1375.0239931921465!2d77.61625532383982!3d12.897742717627613!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae14dce28888cf%3A0xe8336d4e6801af9!2sRadiant%20Lotus!5e0!3m2!1sen!2sin!4v1708772739634!5m2!1sen!2sin", description: "order delivery on your door step" },
  //   { id: 2, name: "South Bangalore", url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d124438.79293039323!2d77.5042046634324!3d12.926204902912694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15a19431f285%3A0x2d7e0ad4ca819fce!2sBangalore%20South%2C%20Bengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1710506540915!5m2!1sen!2sin", description: "Collect the order on Madiwala, KR market" },
  //   { id: 3, name: "Coimbatore", url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d250644.76835243014!2d76.80241834872739!3d11.01426148786984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba859af2f971cb5%3A0x2fc1c81e183ed282!2sCoimbatore%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1710506644725!5m2!1sen!2sin", description: "Collect the order on Gandhipuram" },
  //   { id: 4, name: "Other Location, India", url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15914843.332839767!2d72.40418704339184!3d13.133371455231856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baf1c528a383965%3A0xed77f1a39696ccc7!2sSouth%20India!5e0!3m2!1sen!2sin!4v1710506958341!5m2!1sen!2sin", description: "Please contact to whatsapp/call(+91 556465465) for other location orders" }
  // ];

  const locationOptions = useSelector((state) => { return  state.location.data })
 const getLocalSelected = useSelector(state => state?.commonData?.location)

  useEffect(() => {
   dispatch(retrieveLocations())
  }, [dispatch])

  const path = useLocation();
  const { pathname } = path;
  const getURLCategory = pathname.split('/')[1]

  useEffect(() => {
    setGetDataLocation(locationOptions)
    setSelectedLocation(getLocalSelected)
     if(!getLocalSelected && getURLCategory === ""){
      setIsDialogVisible(true);
     } else if (!getLocalSelected && getURLCategory === "order-confirmation" || !getLocalSelected && getURLCategory === "order-detail"){
      setIsDialogVisible(false);
     }
    
   }, [getLocalSelected, locationOptions])

  const handleLocationChange = (e) => {
    const location = getDataLocation?.find(location => location.locationName === e.target.value);
    setChoosedLocation(location ? location.locationName: "")
    setLocationUrl(location ? location.url : "");
    setLocationDesc(location ? location.description : "")
  }


  const onShowDialog = () => {
    setIsDialogVisible(true);
    const location = getDataLocation?.find(location => location.locationName === selectedLocation);
    setChoosedLocation(location ? location.locationName: "")
    setLocationUrl(location ? location.url : "");
    setLocationDesc(location ? location.description : "")
  };

  const navigate = useNavigate();
  const onHideDialog = () => {
    setIsDialogVisible(false);
    setChoosedLocation("")
  };

  const onConfirmLocation = () => {
   //dispatch(getLocation(changeLocation))
    // let localstorageLocation = changedLocation
    // const getlocation = locationOptions.find(location => location.locationName === localstorageLocation);
    setSelectedLocation(choosedLocation);
    dispatch(getLocation(choosedLocation))

		dispatch(addressDetail({}))
		//dispatch(cartTotal({}))
		// dispatch(cartItems([]))
		//dispatch(cartTotalCount(''))

    setIsDialogVisible(false);
    navigate('/')

  }

  

  const DisplayLocation = () => {
    return (
      <section className="storeLocation">
        <span><img
          loading="lazy"
          src={LocationIcon}
          className="store-image"
          alt="Store Image"
        /></span>
        <select value={choosedLocation} onChange={handleLocationChange}>
        {selectedLocation || choosedLocation ? '' : <option>Choose Location</option>}
          {getDataLocation?.map((location, index) => (
            <option key={index} value={location.locationName}>{location.locationName}</option>
          ))}
        </select>
      </section>
    )
  }

  const HeaderLocation = () => {
    return (
      <section className="storeLocation">
        <span><img
          loading="lazy"
          src={LocationIcon}
          className="store-image"
          alt="Store Image"
        /></span>
        <span onClick={onShowDialog}>{selectedLocation ? selectedLocation : 'Select Location'} </span>
      </section>
    )
  }
  let commonIframeURl = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15914843.332839767!2d72.40418704339184!3d13.133371455231856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baf1c528a383965%3A0xed77f1a39696ccc7!2sSouth%20India!5e0!3m2!1sen!2sin!4v1710506958341!5m2!1sen!2sin"
  return (
    <>

      <HeaderLocation />
      <Dialog header={`Selected Location - ${selectedLocation}`} visible={isDialogVisible} style={{ width: '70vw' }} onHide={onHideDialog}>
        <div className="locationDetailsContainer">
          <section><iframe src={locationUrl ? locationUrl : commonIframeURl } width="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></section>
          <section className="locationDetails">
            <h3>Choose Different Location</h3>
            <DisplayLocation />

            <h4>Note: {locationDesc}</h4>
            {choosedLocation ? <Button  onClick={onConfirmLocation}>Confirm</Button>
            : <Button disabled >Confirm</Button>}
          </section>
        </div>

      </Dialog>
    </>
  );
}