import React, { useState, useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';

import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';

import { useDispatch, useSelector } from 'react-redux';
import {connect} from 'react-redux';
import { createTimerBanner, retrieveTimerBanners, updateTimerBanner, deleteTimerBanner } from '../services/actions/timerBanner';

function TimerBanner() {
  const toast = useRef(null);
  const dispatch = useDispatch()

  const [products, setProducts] = useState([]);


  const [title, setTitle] = React.useState("");
  const [subTitle, setSubTitle] = React.useState("");
  const [timer, setTimer] = React.useState("");
  const [image, setImage] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [discount, setDiscount] = React.useState("");
  const [shopLink, setShopLink] = React.useState("");

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleSubTitleChange = (event) => {
    setSubTitle(event.target.value);
  };


  const handleTimerChange = (event) => {
    setTimer(event.target.value);
  };

  const handleImageChange = (event) => {
    setImage(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleDiscountChange = (event) => {
    setDiscount(event.target.value);
  };

  const handleShopLinkChange = (event) => {
    setShopLink(event.target.value);
  };

  const createBanner = () => {
    let banner = {
      "title": title,
      "subtitle": subTitle,
      "timer": timer,
      "image" : image,
      "description" : description,
      "discount": discount,
      "link": shopLink
    }
    if(title && subTitle && timer && image && description && discount && shopLink){
      dispatch(createTimerBanner(banner))
      setTitle('')
      setSubTitle('')
      setTimer('')
      setDescription('')
      setImage('')
      setDiscount('')
      setShopLink('')

      toast.current.show({ severity: 'success', summary: '', detail: 'Created Banner', life: 3000 });
    } else{
      toast.current.show({ severity: 'error', summary: 'Rejected', detail: 'Please fill all the fields', life: 3000 });
    }
  }

  const getBanner = useSelector((state) => state.timerBanner?.data)

  useEffect(() => {
    dispatch(retrieveTimerBanners())

  }, [dispatch]);

  useEffect(() => {
    setProducts(getBanner);

  }, [getBanner]);
  
  const onRowEditComplete = (e) => {
    let { newData } = e;
    dispatch(updateTimerBanner(newData._id, newData))
  };

  const onRowEditCancel = (e) => {
    let { data } = e;
    dispatch(deleteTimerBanner(data._id))
  }


  const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
  };

  const statusBodyTemplate = (rowData) => {
    return <Tag value={rowData.timer} ></Tag>;
  };

  const allowEdit = (rowData) => {
    return rowData.name !== 'Blue Band';
  };



  return (
    <div className="contentContainer">
      <Toast ref={toast} />
      <h3>Timer Banner</h3>
      <div className="card">
        <TabView>
        <TabPanel header="Edit Banner" leftIcon="pi pi-pencil ml-2">
            <DataTable value={products} editMode="row" dataKey="id" onRowEditComplete={onRowEditComplete} onRowEditCancel={onRowEditCancel} stripedRows scrollHeight="400px" virtualScrollerOptions={{ itemSize: 10 }} tableStyle={{ minWidth: '50rem' }}>
              <Column field="title" header="Title" editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
              <Column field="subtitle" header="Sub Title" editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
              <Column field="timer" header="Timer" body={statusBodyTemplate} editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
              <Column field="image" header="Image Path"  editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
              <Column field="description" header="Description"  editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
              <Column field="discount" header="Discount" editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
              <Column field="link" header="LinkURL" editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
              <Column rowEditor={allowEdit} headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
            
            </DataTable>
          </TabPanel>
          <TabPanel header="Create Banner" leftIcon="pi pi-plus-circle mr-2">
            <div className="adminForm">
              <div class="form-group">
                <label for="title" class="visually-hidden">Title</label>
                <InputText type="text" id="title" class="form-control" placeholder="Title *" aria-label="title" value={title}
                  onChange={handleTitleChange} required  rounded/>
              </div>
              <div class="form-group">
                <label for="subtitle" class="visually-hidden">Sub Title</label>
                <InputText type="text" id="subtitle" class="form-control" placeholder="Sub Title *" aria-label="sub title" value={subTitle}
                  onChange={handleSubTitleChange} required  rounded/>
              </div>
              <div class="form-group">
                <label for="timer" class="visually-hidden">Timer</label>
                <InputText type="date" id="timer" class="form-control" placeholder="Timer *" aria-label="timer" value={timer}
                  onChange={handleTimerChange} required  rounded/>
              </div>
              <div class="form-group">
                <label for="image" class="visually-hidden">Image Path</label>
                <InputText type="text" id="image" class="form-control" placeholder="Image Path *" aria-label="image" value={image}
                  onChange={handleImageChange} required />
              </div>
              <div class="form-group">
                <label for="description" class="visually-hidden">Description</label>
                <InputText type="text" id="description" class="form-control" placeholder="Description *" aria-label="Description" value={description}
                  onChange={handleDescriptionChange} required />
              </div>
              <div class="form-group">
                <label for="discount" class="visually-hidden">Discount</label>
                <InputText type="text" id="discount" class="form-control" placeholder="Discount *" aria-label="discount" value={discount}
                  onChange={handleDiscountChange} required  rounded/>
              </div>
              <div class="form-group">
                <label for="link" class="visually-hidden">Banner Link</label>
                <InputText type="text" id="link" class="form-control" placeholder="Banner link *" aria-label="link" value={shopLink}
                  onChange={handleShopLinkChange} required />
              </div>
              <div className='submitButton'>
                <Button label="Create Banner" onClick={() => createBanner()} rounded ></Button>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </div>

    </div>
  )
}

export default connect(null, {createTimerBanner, retrieveTimerBanners, updateTimerBanner, deleteTimerBanner})(TimerBanner);