import './App.css';
import 'primereact/resources/themes/saga-green/theme.css'
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import AppRouter from './AppRouter'

function App() {
  return (
    <AppRouter />

  );
}

export default App;
