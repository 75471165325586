import {
    CREATE_PRODUCT,
    RETRIEVE_PRODUCTS,
    UPDATE_PRODUCT,
    DELETE_PRODUCT,
    DELETE_ALL_PRODUCTS
  } from "../actions/types";

  const initialState = {};

  function productReducer(state = initialState, action) {
    const { type, payload } = action; 
    switch (type) {
      case CREATE_PRODUCT:
        return {...state, payload}
 
      case RETRIEVE_PRODUCTS:
        return payload;
  
      case UPDATE_PRODUCT:
        return state?.data?.map((state) => {
          if (state._id === payload.data._id) {
            return {
              ...state,
              ...payload,
            };
          } else {
            return state;
          }
        });
  
      case DELETE_PRODUCT:
        return state.data.filter(({ _id }) => _id !== payload.id);
  
      case DELETE_ALL_PRODUCTS:
        return [];
  
      default:
        return state;
    }
  };
  
  export default productReducer;