import { http } from '../../config/configApi';


class LocationCodeService {
    getAll() {
        return http.get(`/locationCode`);
    }

    get(id) {
        return http.get(`/locationCode/${id}`);
    }

    create(data) {
        return http.post("/locationCode", data);
    }

    update(id, data) {
        return http.put(`/locationCode/${id}`, data);
    }

    delete(id) {
        return http.delete(`/locationCode/${id}`);
    }

    deleteAll() {
        return http.delete(`/locationCode`);
    }

    findByTitle(title) {
        return http.get(`/locationCode?title=${title}`);
    }
}

export default new LocationCodeService();

