import React, { useState, useEffect } from "react";
import {Link} from 'react-router-dom'
import './cardBanner.scss';
import Timer from './Timer';
import { useSelector, useDispatch } from 'react-redux';
import { retrieveTimerBanners } from '../../services/actions/timerBanner';
import {connect} from 'react-redux';
import './carousel.scss'
import ShopNowIcon from '../../assets/shopnow-icon.svg'

function CardBanner() {
  const [products, setProducts] = useState([]);

  const dispatch = useDispatch()
  const getBanner = useSelector((state) => { return  state.timerBanner.data })
   useEffect(() => {
    dispatch(retrieveTimerBanners())
   }, [dispatch])

   useEffect(() => {
    setProducts(getBanner)
   }, [getBanner])
  return (
    <div className="cardBanner">
      {products?.length > 0 ? products?.map((product, index)=> (
         <section key={index} className="cardBanner-section">
    
         <img loading="lazy" srcSet={product.image} className="image" />
          <header className="header">{product.title}</header>
          <p className="description">{product.subtitle}</p>
          <Timer timerData={product.timer} />
          <div className="percentage-container">
          <div className="percentage-label">Up to</div>
          <div className="percentage">{product.discount}</div>
        </div>
          <div className="shop-container">
            <Link to={product.link} className="shop-btn">Shop Now</Link>
            <img
              loading="lazy"
              src={ShopNowIcon}
              className="shop-img"
            />
          </div>
        </section>
      )) : ''}
     
      {/* <section className="cardBanner-section cardBanner-section-color">
                <img loading="lazy" src="" alt="Low-Fat Meat" className="image" />
                 <header className="header">Summer Sale</header>
        <p className="description">100% Fresh Fruit</p>
        <div className="percentage-container">
          <div className="percentage-label">Up to</div>
          <div className="percentage">64% OFF</div>
        </div>
                <div className="shop-container">
          <a className="shop-btn">Shop Now</a>
          <img
            loading="lazy"
            src=""
            className="shop-img"
          />
        </div>
            </section>
      <section className="cardBanner-section">
        <img loading="lazy" srcSet="" className="image" />
        <header className="header">Summer Sale</header>
        <p className="description">100% Fresh Fruit</p>
        <div className="percentage-container">
          <div className="percentage-label">Up to</div>
          <div className="percentage">64% OFF</div>
        </div>
        <div className="shop-container">
          <a className="shop-btn">Shop Now</a>
          <img
            loading="lazy"
            src=""
            className="shop-img"
          />
        </div>
      </section> */}
    </div>
  );
}

export default connect(null, {retrieveTimerBanners})(CardBanner);