import * as React from "react";
import './header.scss';
import Location from "./Location";
import LogoHeader from './LogoHeader';

export default function Home(props) {
    return (
      <>
        <Location />
        <LogoHeader />
      </>
    )}