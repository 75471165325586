import {
    CREATE_TIMERBANNER,
    RETRIEVE_TIMERBANNERS,
    UPDATE_TIMERBANNER,
    DELETE_TIMERBANNER,
    DELETE_ALL_TIMERBANNERS
  } from "./types";
  
  import TimerBannerService from "../constants/timerBanner.services";
  
  export const createTimerBanner = (banner) => async (dispatch) => {
    try {
      const res = await TimerBannerService.create(banner);
      dispatch({
        type: CREATE_TIMERBANNER,
        payload: res.data.data,
      });

      return Promise.resolve(res.data.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  export const retrieveTimerBanners = () => async (dispatch) => {
    try {
      const res = await TimerBannerService.getAll();
      dispatch({
        type: RETRIEVE_TIMERBANNERS,
        payload: res.data,
      });
    } catch (err) {
    }
  };
  
  export const updateTimerBanner = (id, data) => async (dispatch) => {
    try {
      const res = await TimerBannerService.update(id, data);
      dispatch({
        type: UPDATE_TIMERBANNER,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  export const deleteTimerBanner = (id) => async (dispatch) => {
    try {
      await TimerBannerService.delete(id);
  
      dispatch({
        type: DELETE_TIMERBANNER,
        payload: { id },
      });
    } catch (err) {
    }
  };
  
  export const deleteAllTimerBanners = () => async (dispatch) => {
    try {
      const res = await TimerBannerService.deleteAll();
  
      dispatch({
        type: DELETE_ALL_TIMERBANNERS,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  export const findTimerBannersByTitle = (title) => async (dispatch) => {
    try {
      const res = await TimerBannerService?.findByTitle(title);
  
      dispatch({
        type: RETRIEVE_TIMERBANNERS,
        payload: res.data,
      });
    } catch (err) {

    }
  };