import {
    CREATE_CATEGORYBANNER,
    RETRIEVE_CATEGORYBANNERS,
    UPDATE_CATEGORYBANNER,
    DELETE_CATEGORYBANNER,
    DELETE_ALL_CATEGORYBANNERS
  } from "../actions/types";

  const initialState = {};

  function categoryBannerReducer(state = initialState, action) {
    const { type, payload } = action; 
    switch (type) {
      case CREATE_CATEGORYBANNER:
        return {...state, payload}
 
      case RETRIEVE_CATEGORYBANNERS:
        return payload;
  
      case UPDATE_CATEGORYBANNER:
        return state?.data?.map((state) => {
          if (state._id === payload.data._id) {
            return {
              ...state,
              ...payload,
            };
          } else {
            return state;
          }
        });
  
      case DELETE_CATEGORYBANNER:
        return state.data.filter(({ _id }) => _id !== payload.id);
  
      case DELETE_ALL_CATEGORYBANNERS:
        return [];
  
      default:
        return state;
    }
  };
  
  export default categoryBannerReducer;