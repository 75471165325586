import React, { useState } from "react";
import { Link } from 'react-router-dom';
import logo from '../../assets/logo-black.jpg';
import { NavLink } from 'react-router-dom';

export default function AdminHeader() {
  const [menu, setMenu] = useState(false);
  const hideMenu = () => {
    setMenu(!menu)
  }
  return (
    <>
      <div className="adminHeader">
        <div className="mobileAdminMenu">
          <div>
            <i className="pi pi-bars" onClick={hideMenu}></i>
            
          </div>
          <Link to="/">
            <img src={logo} alt="SSS Logo" className="logo" />
            <h1><span className="green">Seasonal</span> <span className="orange">Sales</span> <span className="red">Snapee</span></h1>
          </Link>
        </div>
        <div>Admin</div>
      </div>
      {menu? 
      <div className="mobileLeftMenu">
              <NavLink to="/addminn">Search Order</NavLink >
              <NavLink to="/addminn/orders">Orders</NavLink >
              <NavLink to="/addminn/products">Products</NavLink >
              <NavLink to="/addminn/location">Location</NavLink >
              <NavLink  to="/addminn/location-code">Location Code</NavLink >
             <NavLink  to="/addminn/general-data">General Data</NavLink >
              <NavLink to="/addminn/home-banner">Home Banner</NavLink >
              <NavLink to="/addminn/timer-banner">Timer Banner</NavLink >
              <NavLink to="/addminn/category-banner">Cateory Banner</NavLink >
              <NavLink to="/addminn/reports">Reports</NavLink >
              <NavLink  to="/addminn/message">Message Text</NavLink >

            </div> : ''}
    </>
  )
}