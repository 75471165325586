import React from 'react';
import Shipping from '../../assets/shipping.svg';
import Support from '../../assets/support.svg';
import Payment from '../../assets/payment.svg';

const BenefitItem = ({ imageUrl, altText, title, description }) => {
  return (
    <div className="benefit-column">
      <img loading="lazy" src={imageUrl} alt={altText} className="benefit-image" />
      <div className="benefit-content">
        <div className="benefit-title">{title}</div>
        <div className="benefit-description">{description}</div>
      </div>
    </div>
  );
};

const BenefitsSection = () => {
  const benefits = [
    {
      imageUrl: Shipping,
      altText: "Free Shipping Icon",
      title: "Shipping",
      description: "Easy Shipping, Delivered with Care"
    },
    {
      imageUrl: Support,
      altText: "Customer Support Icon",
      title: "Customer Support 24/7",
      description: "Whatsapp/call +91 9606775609"
    },
    {
      imageUrl: Payment,
      altText: "Secure Payment Icon",
      title: "Payment",
      description: "Quick Checkout, Secure Payments"
    },
    // {
    //   imageUrl: "",
    //   altText: "Money-Back Guarantee Icon",
    //   title: "Money-Back Guarantee",
    //   description: "30 Days Money-Back Guarantee"
    // },
  ];

  return (
    <section className="benefits-section">
      {benefits?.map((benefit, index) => (
        <BenefitItem key={index} {...benefit} />
      ))}
      <style jsx>{`
       
      `}</style>
    </section>
  );
}

export default BenefitsSection;