import {
    CREATE_HOMEBANNER,
    RETRIEVE_HOMEBANNERS,
    UPDATE_HOMEBANNER,
    DELETE_HOMEBANNER,
    DELETE_ALL_HOMEBANNERS
  } from "./types";
  
  import HomeBannerService from "../constants/homeBanner.services";
  
  export const createHomeBanner = (banner) => async (dispatch) => {
    try {
      const res = await HomeBannerService.create(banner);
      dispatch({
        type: CREATE_HOMEBANNER,
        payload: res.data.data,
      });

      return Promise.resolve(res.data.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  export const retrieveHomeBanners = () => async (dispatch) => {
    try {
      const res = await HomeBannerService.getAll();
      
      dispatch({
        type: RETRIEVE_HOMEBANNERS,
        payload: res.data,
      });
    } catch (err) {
    }
  };
  
  export const updateHomeBanner = (id, data) => async (dispatch) => {
    try {
      const res = await HomeBannerService.update(id, data);
      dispatch({
        type: UPDATE_HOMEBANNER,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  export const deleteHomeBanner = (id) => async (dispatch) => {
    try {
      await HomeBannerService.delete(id);
  
      dispatch({
        type: DELETE_HOMEBANNER,
        payload: { id },
      });
    } catch (err) {
    }
  };
  
  export const deleteAllHomeBanners = () => async (dispatch) => {
    try {
      const res = await HomeBannerService.deleteAll();
  
      dispatch({
        type: DELETE_ALL_HOMEBANNERS,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  export const findHomeBannersByTitle = (title) => async (dispatch) => {
    try {
      const res = await HomeBannerService.findByTitle(title);
  
      dispatch({
        type: RETRIEVE_HOMEBANNERS,
        payload: res.data,
      });
    } catch (err) {
    }
  };