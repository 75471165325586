import React, { useState, useEffect } from "react";
import './banner.scss';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { retrieveHomeBanners } from '../../services/actions/homeBanner';
import { connect } from 'react-redux';

function HomeBanner() {
  const [products, setProducts] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);

  const dispatch = useDispatch()
  const getBanner = useSelector((state) => { return state.homeBanner?.data })
  useEffect(() => {
    dispatch(retrieveHomeBanners())
  }, [dispatch])

  useEffect(() => {
    setProducts(getBanner ? getBanner[0] : "")
  }, [getBanner])

  useEffect(() => {
    const timer = setTimeout(() => {
      setImageLoaded(true);
    }, 4000); // 2000 milliseconds (2 seconds)

    return () => clearTimeout(timer); // Cleanup on component unmount
  }, []);



  return (
    <>
      {products ?
        <section className="mainBanner">
          {imageLoaded ? (
            <img
              src={products?.image}
              alt="Fresh Organic Food Background"
              className="backgroundImage"
            />
          ) : (
            <div id="loader" class="loader-container">
              <div class="loader"></div>
            </div>
          )}

          <header className="titleContainer">
            {products?.title}
          </header>
          <section className="promotion">
            <div className="highlightIndicator" />
            {/* <article className="promotionDetails">
              <div className="promotionText">
                <div className="discountAmount">{products?.discount}</div>
              </div>
              <div className="shippingInfo">{products?.description}</div>
            </article> */}

            {/* <Link to={products?.link} className="shop-button">
              Shop Now

            </Link> */}

          </section>

        </section>
        : <div className="noData">No Data available</div>}
    </>
  );
}

export default connect(null, { retrieveHomeBanners })(HomeBanner);