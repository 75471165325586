import { http } from '../../config/configApi';


class TimerBannerService {
    getAll() {
        return http.get(`/timerBanner`);
    }

    get(id) {
        return http.get(`/timerBanner/${id}`);
    }

    create(data) {
        return http.post("/timerBanner", data);
    }

    update(id, data) {
        return http.put(`/timerBanner/${id}`, data);
    }

    delete(id) {
        return http.delete(`/timerBanner/${id}`);
    }

    deleteAll() {
        return http.delete(`/timerBanner`);
    }

    findByTitle(title) {
        return http.get(`/timerBanner?title=${title}`);
    }
}

export default new TimerBannerService();

