import {
    CREATE_LOCATIONCODE,
    RETRIEVE_LOCATIONCODES,
    UPDATE_LOCATIONCODE,
    DELETE_LOCATIONCODE,
    DELETE_ALL_LOCATIONCODES
  } from "../actions/types";

  const initialState = {};

  function locationCodeReducer(state = initialState, action) {
    const { type, payload } = action; 
    switch (type) {
      case CREATE_LOCATIONCODE:
        return {...state, payload}
 
      case RETRIEVE_LOCATIONCODES:
        return payload;
  
      case UPDATE_LOCATIONCODE:
        return state?.data?.map((state) => {
          if (state._id === payload.data._id) {
            return {
              ...state,
              ...payload,
            };
          } else {
            return state;
          }
        });
  
      case DELETE_LOCATIONCODE:
        return state.data.filter(({ _id }) => _id !== payload.id);
  
      case DELETE_ALL_LOCATIONCODES:
        return [];
  
      default:
        return state;
    }
  };
  
  export default locationCodeReducer;