import React, { useState, useEffect } from 'react';
import './main.scss'
import { useSelector, useDispatch } from 'react-redux';
import BenefitsSection from '../components/Header/BenefitsSection';
import HomeBanner from '../components/Banner/HomeBanner';
import PromotionalBanner from '../components/Banner/PromotionalBanner';
import CardBanner from '../components/Banner/CardBanner'
import { Fireworks } from 'fireworks/lib/react'
import Marquee from "react-fast-marquee";
import { retrieveLocationCodes } from '../services/actions/locationCode';
import { retrieveGeneralDatas } from '../services/actions/generalData';

export default function Home(props) {
  const dispatch = useDispatch()

  
  const [showConfetti, setShowConfetti] = useState(true);
  useEffect(() => {
    // Set a timeout to hide confetti after 5 seconds
    const timer = setTimeout(() => setShowConfetti(false), 10000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    dispatch(retrieveLocationCodes())
    dispatch(retrieveGeneralDatas())
   }, [dispatch])
   const getrunningText = useSelector((state) => { return  state.generalData.data })
   const runningText = getrunningText ? getrunningText[0]?.runningText : "";

  let fxProps = {
    count: 3,
    interval: 200,
    colors: ['#80a902', '#e78f2a', '#ef5705'],
    bubbleSizeMinimum: 3,
    bubbleSizeMaximum:10,
    particleTimeout: 5000,
    calc: (props, i) => ({
      ...props,
      x: (i + 1) * (window.innerWidth / 3) - (i + 1) * 100,
      y: 200 + Math.random() * 100 - 50 + (i === 2 ? -80 : 0)
    })
  }
  return (
    <>
     <Marquee
   
    style={{
      background: "yellow", paddingTop:"5px", paddingBottom:"5px" // Your own styling (Optional)
    }}
  >
    {runningText}
  </Marquee>
        <div className="bannerContainer">
        {/* {showConfetti && (
        <Fireworks style={{ width:"100%" }} {...fxProps} />
        )} */}
       

          <HomeBanner />
          {/* <PromotionalBanner /> */}
        </div>
        <BenefitsSection />
        {/* <CardBanner /> */}
    </>
  )}