import * as React from "react";
import moment from "moment";

function CountdownTimer(timerData) {
  const calculateTimeLeft = () => {
    let covertDate = moment(timerData.timerData).utc().format('YYYY-MM-DD')
    const difference = +new Date(covertDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        Days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        Hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        Minutes: Math.floor((difference / 1000 / 60) % 60),
        Seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft());

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <>
      <div className="countdown-container">
        {Object.keys(timeLeft)?.map((unit) => (
          <div className="countdown-item" key={unit}>
            <div className="countdown-value">{timeLeft[unit]}</div>
            <div className="countdown-label">{unit}</div>
          </div>
        ))}
      </div>

      <style jsx>{`
        .countdown-container {
          position: relative;
          display: flex;
          margin-top: 18px;
          gap: 8px;
          font-weight: 400;
          white-space: nowrap;
        }
        
        @media (max-width: 991px) {
          .countdown-container {
            white-space: initial;
          }
        }

        .countdown-item {
          border-radius: 6px;
          display: flex;
          flex-grow: 1;
          flex-basis: 0%;
          flex-direction: column;
          background:red;
          padding:5px;
        }
        
        @media (max-width: 991px) {
          .countdown-item {
            white-space: initial;
          }
        }

        .countdown-value {
          color: var(--Gray-Scale-White, #fff);
          font: 24px/150% Poppins, sans-serif;
        }

        .countdown-label {
          color: rgba(255, 255, 255, 0.8);
          letter-spacing: 0.36px;
          text-transform: uppercase;
          margin-top: 4px;
          font: 12px/100% Poppins, sans-serif;
        }

        .countdown-separator {
          color: rgba(255, 255, 255, 0.6);
          align-self: start;
          font: 24px/150% Poppins, sans-serif;
        }
      `}</style>
    </>
  );
}

export default CountdownTimer;