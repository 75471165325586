import {
  LOCATION,
  CARTCOUNT,
  CARTITEMS,
  TOTALAMOUNT,
  ADDRESS,
  PAYMENT
} from "../actions/types";


const initialState = {
  location: '',
  cartCount: 0,
  cartItems: [],
  totalAmount:[],
  address: [],
};

  function commonDataReducer(state = initialState, action) {
    const { type, payload } = action;
    
    switch (type) {
      case LOCATION:
        return {...state, location: payload};
  
      case CARTCOUNT:
        return {...state, cartCount: payload};
  
      case CARTITEMS:
        return {...state, cartItems: payload};
  
      case TOTALAMOUNT:
        return {...state, totalAmount: payload};
  
      case ADDRESS:
        return {...state, address: payload};

      case PAYMENT:
          return []
  
      default:
        return state;
    }
  };
  
  export default commonDataReducer;