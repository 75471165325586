export const CREATE_CATEGORYBANNER = "CREATE_CATEGORYBANNER";
export const RETRIEVE_CATEGORYBANNERS = "RETRIEVE_CATEGORYBANNER";
export const UPDATE_CATEGORYBANNER = "UPDATE_CATEGORYBANNER";
export const DELETE_CATEGORYBANNER = "DELETE_CATEGORYBANNER";
export const DELETE_ALL_CATEGORYBANNERS = "DELETE_ALL_CATEGORYBANNER";

export const CREATE_TIMERBANNER = "CREATE_TIMERBANNER";
export const RETRIEVE_TIMERBANNERS = "RETRIEVE_TIMERBANNER";
export const UPDATE_TIMERBANNER = "UPDATE_TIMERBANNER";
export const DELETE_TIMERBANNER = "DELETE_TIMERBANNER";
export const DELETE_ALL_TIMERBANNERS = "DELETE_ALL_TIMERBANNER";

export const CREATE_HOMEBANNER = "CREATE_HOMEBANNER";
export const RETRIEVE_HOMEBANNERS = "RETRIEVE_HOMEBANNER";
export const UPDATE_HOMEBANNER = "UPDATE_HOMEBANNER";
export const DELETE_HOMEBANNER = "DELETE_HOMEBANNER";
export const DELETE_ALL_HOMEBANNERS = "DELETE_ALL_HOMEBANNER";

export const CREATE_LOCATION = "CREATE_LOCATION";
export const RETRIEVE_LOCATIONS = "RETRIEVE_LOCATION";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const DELETE_LOCATION = "DELETE_LOCATION";
export const DELETE_ALL_LOCATIONS = "DELETE_ALL_LOCATION";

export const CREATE_LOCATIONCODE = "CREATE_LOCATIONCODE";
export const RETRIEVE_LOCATIONCODES = "RETRIEVE_LOCATIONCODE";
export const UPDATE_LOCATIONCODE = "UPDATE_LOCATIONCODE";
export const DELETE_LOCATIONCODE = "DELETE_LOCATIONCODE";
export const DELETE_ALL_LOCATIONCODES = "DELETE_ALL_LOCATIONCODE";

export const CREATE_GENERALDATA = "CREATE_GENERALDATA";
export const RETRIEVE_GENERALDATAS = "RETRIEVE_GENERALDATA";
export const UPDATE_GENERALDATA = "UPDATE_GENERALDATA";
export const DELETE_GENERALDATA = "DELETE_GENERALDATA";
export const DELETE_ALL_GENERALDATAS = "DELETE_ALL_GENERALDATA";

export const CREATE_ORDER = "CREATE_ORDER";
export const RETRIEVE_ORDERS = "RETRIEVE_ORDER";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const DELETE_ORDER = "DELETE_ORDER";
export const DELETE_ALL_ORDERS = "DELETE_ALL_ORDER";

export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const RETRIEVE_PRODUCTS = "RETRIEVE_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_ALL_PRODUCTS = "DELETE_ALL_PRODUCT";

export const LOCATION = "LOCATION";
export const CARTCOUNT = "CARTCOUNT";
export const CARTITEMS = "CARTITEMS";
export const TOTALAMOUNT = "TOTALAMOUNT";
export const ADDRESS = "ADDRESS";
export const PAYMENT = "PAYMENT";






