import * as React from "react";
import './footer.scss';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png';
import WhatsApp from "../WhatsAppButton";
import { useSelector } from 'react-redux';

export default function Footer() {
  const storeCartCount = useSelector(state => state?.commonData?.cartCount)
  const checkLocation = useSelector((state) => { return  state.commonData.location })
  return (
    <>
    <div className="whatapp"><WhatsApp /></div>
    {storeCartCount > 0 ?
    <div className="footerCartItem"><Link to="/cart">View to Cart Items</Link></div> : ""}
    <footer className="footer">
      <section className="footer-content">
          <section className="section">
            <div className="information">
              <div className="footerLogo">
              <img
                loading="lazy"
                src={logo}
                alt="SSS logo"
                className="logo"
              />
              <h1 className="brand-name"><span className="green">Seasonal</span> <span className="orange">Sales</span> <span className="red">Snapee</span></h1>
              </div>
              </div>
              <div className="contact-info">
                <span className="phone">+91 9606775609</span>
                <span className="separator">or</span>
                <span className="email">seasonalsalesnapee@gmail.com</span>
              </div>
          </section>
          <div className="footerMenu">
          <section className="section">
            <h2 className="section-title">Products</h2>
            <ul className="navigation-list">
            {/* <li><Link to="/crackers" className="menu-item">Crackers</Link></li> */}
            {/* {checkLocation === "Bangalore" ?
        <li><Link to="/standard-crackers" className="menu-item">Standard Crackers</Link></li>
        : ""} */}
            </ul>
          </section>
          <section className="section">
            <h2 className="section-title">Links</h2>
            {/* <ul className="navigation-list">
              <li>  <Link to="/contact" className="menu-item">Contact / Payment</Link></li>
             
            </ul> */}
          </section>
          <section className="section">
            <h2 className="section-title">Social media</h2>
            <ul className="navigation-list">
             
              <li><a href="https://www.instagram.com/seasonalsalesnapee/" target="_blank"><i className="pi pi-instagram" style={{ fontSize: '1rem' }}></i> Instagram</a></li>
            </ul>
          </section>
          </div>
      </section>
      {/* <section className="footerpoint">
        <p>Experience seamless trading with our exclusive online platform. All trading activities are conducted through our secure and user-friendly website, ensuring a smooth and efficient trading experience.</p>
        <p>Due to a 2018 Supreme Court order, online sales of firecrackers are not permitted.  We value our customers and at the same time, respect jurisdiction. To purchase, please add your desired items to the cart and submit your request using the enquiry button. We’ll contact you within 24 hours to confirm your order via WhatsApp or phone call. we use legal transport services.</p></section>
        <p>Enjoy your Diwali with a sparkling array of crackers! Wishing you a festival full of joy, light, and prosperity. Happy Diwali!</p> */}
      <section className="footer-bottom">
        <p>Seasonal Sale Snapee 2024</p>
       
      </section>
    </footer>
    </>
  );
}