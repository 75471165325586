import {
    CREATE_CATEGORYBANNER,
    RETRIEVE_CATEGORYBANNERS,
    UPDATE_CATEGORYBANNER,
    DELETE_CATEGORYBANNER,
    DELETE_ALL_CATEGORYBANNERS
  } from "./types";
  
  import CategoryBannerService from "../constants/categoryBanner.services";
  
  export const createCategoryBanner = (banner) => async (dispatch) => {
    try {
      const res = await CategoryBannerService.create(banner);
      dispatch({
        type: CREATE_CATEGORYBANNER,
        payload: res.data.data,
      });

      return Promise.resolve(res.data.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  export const retrieveCategoryBanners = () => async (dispatch) => {
    try {
      const res = await CategoryBannerService.getAll();
      dispatch({
        type: RETRIEVE_CATEGORYBANNERS,
        payload: res.data,
      });
    } catch (err) {
    }
  };
  
  export const updateCategoryBanner = (id, data) => async (dispatch) => {
    try {
      const res = await CategoryBannerService.update(id, data);
      dispatch({
        type: UPDATE_CATEGORYBANNER,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  export const deleteCategoryBanner = (id) => async (dispatch) => {
    try {
      await CategoryBannerService.delete(id);
  
      dispatch({
        type: DELETE_CATEGORYBANNER,
        payload: { id },
      });
    } catch (err) {
    }
  };
  
  export const deleteAllCategoryBanners = () => async (dispatch) => {
    try {
      const res = await CategoryBannerService.deleteAll();
  
      dispatch({
        type: DELETE_ALL_CATEGORYBANNERS,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  export const findCategoryBannersByTitle = (title) => async (dispatch) => {
    try {
      const res = await CategoryBannerService?.findByTitle(title);
  
      dispatch({
        type: RETRIEVE_CATEGORYBANNERS,
        payload: res.data,
      });
    } catch (err) {
    }
  };