import React, { useState, useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';

import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';

import { useDispatch, useSelector } from 'react-redux';
import { createGeneralData, retrieveGeneralDatas, updateGeneralData, deleteGeneralData } from '../services/actions/generalData';

function GeneralData() {
  const toast = useRef(null);
  const dispatch = useDispatch()

  const [products, setProducts] = useState([]);


  const [runningText, setRunningText] = React.useState("");
  const [orderValue, setOrderValue] = React.useState();

  const handleRunningTextChange = (event) => {
    setRunningText(event.target.value);
  };

  const handleOrderValueChange = (event) => {
    setOrderValue(event.target.value);
  };

  const createNewOrderValue = () => {
    let data = {
      "runningText": runningText,
      "minOrderValue": orderValue,
    }
    if(runningText && orderValue){
      dispatch(createGeneralData(data))
      setOrderValue()
      setRunningText('')

      toast.current.show({ severity: 'success', summary: '', detail: 'Created General Data', life: 3000 });
    } else{
      toast.current.show({ severity: 'error', summary: 'Rejected', detail: 'Please fill all the fields', life: 3000 });
    }
  }

  const getGeneralData = useSelector((state) => state.generalData?.data)

  useEffect(() => {
    dispatch(retrieveGeneralDatas())

  }, [dispatch]);
  
 
  useEffect(() => {
    setProducts(getGeneralData);

  }, [getGeneralData]);

  const onRowEditComplete = (e) => {
    let { newData } = e;
    dispatch(updateGeneralData(newData._id, newData))
  };

  const onRowEditCancel = (e) => {
    let { data } = e;
    dispatch(deleteGeneralData(data._id))
  }


  const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
  };

  const statusBodyTemplate = (rowData) => {
    return <Tag value={rowData.locationCode} ></Tag>;
  };

  const allowEdit = (rowData) => {
    return rowData.name !== 'Blue Band';
  };



  return (
    <div className="contentContainer">
      <Toast ref={toast} />
      <h3>General Data</h3>
      <div className="card">
        <TabView>
         
        <TabPanel header="Edit General Data" leftIcon="pi pi-pencil ml-2">
            <DataTable value={products} editMode="row" dataKey="id" onRowEditComplete={onRowEditComplete} onRowEditCancel={onRowEditCancel} stripedRows scrollHeight="400px" virtualScrollerOptions={{ itemSize: 10 }} tableStyle={{ minWidth: '50rem' }}>
              <Column field="runningText" header="Running Text" editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
              <Column field="minOrderValue" header="Minimum Order Value" editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
             <Column rowEditor={allowEdit} headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
            
            </DataTable>
          </TabPanel>
          <TabPanel header="Create General Data" leftIcon="pi pi-plus-circle mr-2">
            <div className="adminForm">
              <div class="form-group">
                <label for="runningText" class="visually-hidden">Add Running Text</label>
                <InputText type="text" id="runningText" class="form-control" placeholder="Running Text *" aria-label="runningText" value={runningText}
                  onChange={handleRunningTextChange} required  rounded/>
              </div>    
              <div class="form-group">
                <label for="orderValue" class="visually-hidden">Add Minimun Order Value</label>
                <InputText type="text" id="orderValue" class="form-control" placeholder="Minimum Order Value *" aria-label="orderValue" value={orderValue}
                  onChange={handleOrderValueChange} required  rounded/>
              </div>           
              <div className='submitButton'>
                <Button label="Create" onClick={() => createNewOrderValue()} rounded ></Button>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </div>

    </div>
  )
}

export default GeneralData;