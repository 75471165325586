import {
    CREATE_ORDER,
    RETRIEVE_ORDERS,
    UPDATE_ORDER,
    DELETE_ORDER,
    DELETE_ALL_ORDERS,
  } from "../actions/types";

  const initialState = {};

  function orderReducer(state = initialState, action) {
    const { type, payload } = action; 
    switch (type) {
      case CREATE_ORDER:
        return {...state, payload}
 
      case RETRIEVE_ORDERS:
        return payload;
  
      case UPDATE_ORDER:
        return state?.data?.map((state) => {
          if (state._id === payload.data._id) {
            return {
              ...state,
              ...payload,
            };
          } else {
            return state;
          }
        });
  
      case DELETE_ORDER:
        return state.data.filter(({ _id }) => _id !== payload.id);
  
      case DELETE_ALL_ORDERS:
        return [];
  
      default:
        return state;
    }
  };
  
  export default orderReducer;