import { combineReducers } from "redux";
import categoryBanner from "./categoryBanner";
import timerBanner from "./timerBanner";
import commonData from './commonData';
import homeBanner from './homeBanner';
import location from './location';
import product from './product';
import order from './order';
import locationCode from './locationCode';
import generalData from './generalData';

export default combineReducers({
    categoryBanner,
    timerBanner,
    homeBanner,
    location,
    product,
    order,
    commonData,
    locationCode,
    generalData
});