import React, { useRef, useState, useEffect } from 'react';
import { Toast } from 'primereact/toast';


const information = [{
  "title": "Order placement",
  "message": `Hi ,

Thank you for placing your order with us! You can view your order details using the following link: https://seasonalsalesnapee.in/order-detail/
To complete your purchase, please make the payment via GPay or PhonePe within the next 3 days to this number 9597820439. After completing the payment, kindly send us a screenshot for verification.
If you have any questions, feel free to reach out.

Best regards,
SSS Team`

}, {
  "title": "Bank Account",
  "message": `Hi ,

Please make the payment via below Bank account.
Bank: Indian Bank
Account No: 615869202
IFSC: IDIB000P168
Account Name: S Pradhish Kumar
Address: Rajapalayam, Tamilnadu

If you have any questions or need further assistance, feel free to ask.

Best,
SSS Team`
},
{
  "title": "After payment",
  "message": `Hi ,

Thank you for your payment! We’ve received it successfully. Your order will be processed and shipped within the next 2 to 3 weeks.
Once your order is shipped, we’ll provide you with a tracking ID so you can monitor its progress.
If you have any questions or need further assistance, feel free to ask.

Best,
SSS Team`
},

{
  "title": "Payment reminder",
  "message": `Hi ,

Just a friendly reminder that payment for your cracker order was due [specific date]. Please make the payment via GPay or PhonePe as soon as possible to this number 9597820439. If you’ve already paid, please share a screenshot with us..

Thanks!`
},

{
  "title": "After shipped",
  "message": `Hi ,

Good news! Your order has been shipped. You can track your shipment using the following link: https://seasonalsalesnapee.in/order-detail/

If you have any questions or need further assistance, please let us know.
  
Thank you for your purchase!

Best,
SSS Team`
},

{
  "title": "After collected",
  "message": `Hi! I hope your order arrived smoothly. Enjoy the celebrations with your family and friends. Advance Happy Diwali! 🎉`
},

{
  "title": "If not collected",
  "message": `Hi ,

Just a quick reminder that your order is still waiting for pickup. Is there any reason for the delay or anything we can help with?

Thanks!`
},
{
  "title": "Feedback",
  "message": `Hi ,

We hope you’re enjoying your crackers! We’d love to hear your feedback on your recent purchase. Could you please let us know how everything went and if there’s anything we can improve?

Your input is valuable to us and helps us serve you better.
  
Thank you for choosing us!
  
Best,
SSS Team`
},
{
  "title": "Greetings",
  "message": `Thank you for contacting Seasonal Sale Snapee. How can we assist you today?`
},
{
  "title": "Different Location (Positive)",
  "message": `Thank you for contacting Seasonal Sale Snapee. Yes, you can proceed with the order. Please enter the code [] in the billing address section`
},
{
  "title": "Different Location (Negative)",
  "message": `Thank you for contacting Seasonal Sale Snapee. Unfortunately, orders cannot be processed for this location. Please call us for more details or assistance`
}]

const MessageText = () => {
  const toast = useRef(null);
  const [notifications, setNotifications] = useState([]);
  const [copiedText, setCopiedText] = useState('');
  useEffect(() => {
    // Simulate fetching data
    const fetchData = async () => {
      const data = information
      setNotifications(data);
    };

    fetchData();
  }, []);

  // Helper function to copy text to clipboard
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => success(),
      (err) => alert('Failed to copy text: ' + err)
    );
  };

  const success = () => {
    toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'Copied', life: 3000 })
  }

// Handle change event for <textarea>
const handleChange = (index, event) => {
  const newNotifications = [...notifications];
  newNotifications[index].message = event.target.value;
  setNotifications(newNotifications);
};



  const handleCopy = (text) => {
    setCopiedText(text);
    copyToClipboard(text);
  };

  const handleClear = () => {
    window.location.reload();
  };

  return (
    <div>
      <Toast ref={toast} />
      <h3>Notification Table</h3>
      <button onClick={handleClear}>Clear All</button>
      <table className="messageContainer">
        <thead>
          <tr>
            <th>Title</th>
            <th>Message</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {notifications && notifications?.map((notify, index) => (
            <tr key={index}>
              <td>{notify.title}</td>
              <td><textarea value={notify.message}   onChange={(e) => handleChange(index, e)}></textarea></td>
              <td>
                <button onClick={() => handleCopy(notify.message)}>Copy</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MessageText;
