import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch, } from 'react-redux'
import { Carousel } from 'primereact/carousel';
import {connect} from 'react-redux';
import { retrieveCategoryBanners } from '../../services/actions/categoryBanner'
import 'primereact/resources/primereact.min.css';
import './carousel.scss'

const CarouselBanner = (props) => {
  const [products, setProducts] = useState([]);
  const [imageLoaded, setImageLoaded] = useState(false);
  
  const responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '600px',
      numVisible: 2,
      numScroll: 2
    },
    {
      breakpoint: '480px',
      numVisible: 1,
      numScroll: 1
    }
  ];

  const dispatch = useDispatch()
  const getBanner = useSelector((state) => { return  state.categoryBanner.data })

  const location = useLocation();
  const { pathname } = location;
  const getURLCategory = pathname.split('/')[1]

  const getCategoryBanner = getBanner?.find(data => data?.category?.toLowerCase() === getURLCategory);

   useEffect(() => {
    dispatch(retrieveCategoryBanners())
   }, [dispatch])

   useEffect(() => {
    setProducts([getCategoryBanner])
   }, [getCategoryBanner])

   useEffect(() => {
    const timer = setTimeout(() => {
      setImageLoaded(true);
    }, 2000); // 2000 milliseconds (2 seconds)

    return () => clearTimeout(timer); // Cleanup on component unmount
  }, []);



  const productTemplate = (product) => {
    return (
      <div className="carousel-item">
        <div className="carousel-item-content">
          <div className="p-mb-3">
            <img src={product?.image} alt={product?.title} className="carousel-image"/>
          </div>
            <div className="carousel-name">{product?.title}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <section className="carouselBanner">
        {imageLoaded ?
         (<Carousel value={products} prevIcon nextIcon showNavigators={true} autoplayInterval={5000} circular showArrows={false} numVisible={1} numScroll={1} responsiveOptions={responsiveOptions} itemTemplate={productTemplate} />
         ): (<div id="loader" class="loader-container">
         <div class="loader"></div>
       </div>)}
        </section>
    </>
  );
}

export default connect(null, {retrieveCategoryBanners})(CarouselBanner);