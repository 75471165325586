import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

import Home from './pages/Home';
import NavMenu from './components/NavMenu';
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import Contact from './pages/Contact';
import About from './pages/About';
import Category from './pages/Category';
import Cart from './pages/Cart'
import Checkout from './pages/Checkout';
import OrderConfirmation from './components/OrderConfirmation';
import OrderDetail from './pages/OrderDetails';

import Admin from './admin/Dashboard';
import AdminHeader from './admin/components/AdminHeader';
import AdminLeftMenu from './admin/components/AdminLeftMenu';
import SearchOrder from './admin/SearchOrder';
import Products from './admin/Products';
import Location from './admin/Location';
import HomeBanner from './admin/HomeBanner';
import TimerBanner from './admin/TimerBanner';
import CategoryBanner from './admin/CategoryBanner';
import LocationCode from './admin/LocationCode';
import GenaralData from './admin/GeneralData';
import Reports from './admin/Reports';
import MessageText from './admin/MessageText';
import NotFound from './pages/NotFound'

const AppRouter = () => {
  const location = useLocation();
  const { pathname } = location;
  const getAdminURL = pathname.split('/')[1];
  const checkLocation = useSelector((state) => { return  state.commonData.location })

  return (
    <>
      {getAdminURL !== "addminn" ?
        <div className="App">
          <Header />
          <NavMenu />
          <Routes>
            <Route
              path="*"
              element={<Home />}
            />
            <Route path="/" exact element={<Home />} />
            {/* <Route path="/terms" element={<About />} /> */}
            {/* <Route path="/contact" element={<Contact />} /> */}
            {/* <Route path="/crackers" element={<Category />} /> */}
            {/* { checkLocation === "Bangalore" ?
            <Route path="/standard-fireworks" element={<Category />} /> : ""} */}
            {/* <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/order-confirmation/:orderId" element={<OrderConfirmation />} />
            <Route path="/order-detail/:orderId" element={<OrderDetail />} /> */}
             {/* Catch-all route for undefined paths */}
             <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </div>
        :
        <div className="App">
          <AdminHeader />
          <div className="adminContainer">
            <div className="adminLeftSection">
              <AdminLeftMenu />
            </div>
            <div className="adminRightSection">
              <Routes>
                {/* <Route path="/addminn/orders" element={<Admin />} /> */}
                {/* <Route path="/addminn" element={<SearchOrder />} />
                <Route path="/addminn/products" element={<Products />} />
                <Route path="/addminn/location" element={<Location />} /> */}
                <Route path="/addminn/home-banner" element={<HomeBanner />} />
                {/* <Route path="/addminn/timer-banner" element={<TimerBanner />} /> */}
                {/* <Route path="/addminn/category-banner" element={<CategoryBanner />} />
                <Route path="/addminn/location-code" element={<LocationCode />} /> */}
                <Route path="/addminn/general-data" element={<GenaralData />} />
                {/* <Route path="/addminn/reports" element={<Reports />} />
                <Route path="/addminn/message" element={<MessageText />} /> */}
              </Routes>
            </div>
          </div>

        </div>
      }
    </>

  );
}

export default AppRouter;
