import {
    CREATE_GENERALDATA,
    RETRIEVE_GENERALDATAS,
    UPDATE_GENERALDATA,
    DELETE_GENERALDATA,
    DELETE_ALL_GENERALDATAS
  } from "./types";
  
  import GeneralDataService from "../constants/generalData.services";
  
  export const createGeneralData = (banner) => async (dispatch) => {
    try {
      const res = await GeneralDataService.create(banner);
      dispatch({
        type: CREATE_GENERALDATA,
        payload: res.data.data,
      });

      return Promise.resolve(res.data.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  export const retrieveGeneralDatas = () => async (dispatch) => {
    try {
      const res = await GeneralDataService.getAll();
      dispatch({
        type: RETRIEVE_GENERALDATAS,
        payload: res.data,
      });
    } catch (err) {
    }
  };
  
  export const updateGeneralData = (id, data) => async (dispatch) => {
    try {
      const res = await GeneralDataService.update(id, data);
      dispatch({
        type: UPDATE_GENERALDATA,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  export const deleteGeneralData = (id) => async (dispatch) => {
    try {
      await GeneralDataService.delete(id);
  
      dispatch({
        type: DELETE_GENERALDATA,
        payload: { id },
      });
    } catch (err) {
    }
  };
  
  export const deleteAllGeneralDatas = () => async (dispatch) => {
    try {
      const res = await GeneralDataService.deleteAll();
  
      dispatch({
        type: DELETE_ALL_GENERALDATAS,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  export const findGeneralDatasByTitle = (title) => async (dispatch) => {
    try {
      const res = await GeneralDataService?.findByTitle(title);
  
      dispatch({
        type: RETRIEVE_GENERALDATAS,
        payload: res.data,
      });
    } catch (err) {
    }
  };