import {
    CREATE_LOCATIONCODE,
    RETRIEVE_LOCATIONCODES,
    UPDATE_LOCATIONCODE,
    DELETE_LOCATIONCODE,
    DELETE_ALL_LOCATIONCODES
  } from "./types";
  
  import LocationCodeService from "../constants/locationCode.services";
  
  export const createLocationCode = (code) => async (dispatch) => {
    try {
      const res = await LocationCodeService.create(code);
      dispatch({
        type: CREATE_LOCATIONCODE,
        payload: res.data.data,
      });

      return Promise.resolve(res.data.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  export const retrieveLocationCodes = () => async (dispatch) => {
    try {
      const res = await LocationCodeService.getAll();
      dispatch({
        type: RETRIEVE_LOCATIONCODES,
        payload: res.data,
      });
    } catch (err) {
    }
  };
  
  export const updateLocationCode = (id, data) => async (dispatch) => {
    try {
      const res = await LocationCodeService.update(id, data);
      dispatch({
        type: UPDATE_LOCATIONCODE,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  export const deleteLocationCode = (id) => async (dispatch) => {
    try {
      await LocationCodeService.delete(id);
  
      dispatch({
        type: DELETE_LOCATIONCODE,
        payload: { id },
      });
    } catch (err) {
    }
  };
  
  export const deleteAllLocationCodes = () => async (dispatch) => {
    try {
      const res = await LocationCodeService.deleteAll();
  
      dispatch({
        type: DELETE_ALL_LOCATIONCODES,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  export const findLocationCodesByTitle = (title) => async (dispatch) => {
    try {
      const res = await LocationCodeService?.findByTitle(title);
  
      dispatch({
        type: RETRIEVE_LOCATIONCODES,
        payload: res.data,
      });
    } catch (err) {
    }
  };